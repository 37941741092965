
export default {
  data () {
    return {
      bodyStyle: null
    }
  },
  methods: {
    bodyScale() {
      const clientWidth = document.documentElement.clientWidth;
      const wScale = clientWidth / 1920; // 分母——设计稿的尺寸高度
      const clientHeight = document.documentElement.clientHeight;
      const hScale = clientHeight / 1080; // 分母——设计稿的尺寸高度
      const ratio = Math.min(wScale, hScale)
      document.body.style.zoom = ratio;
      document.styleSheets[document.styleSheets.length - 1].insertRule('canvas {zoom: ' + 1 / ratio + '}');
      document.styleSheets[document.styleSheets.length - 1].insertRule('canvas {transform: scale(' + ratio + ')}');
      document.styleSheets[document.styleSheets.length - 1].insertRule('canvas {transform-origin: 0 0}');
    },
    setPageTitle(){
      document.title = this.$route.meta.title || '河南智慧林业综合平台'
    },
  },
  mounted () {
    // this.bodyStyle = document.createElement('style');
    // this.bodyStyle.innerHTML = `body{width:1920px; height:1080px!important;}`;
    // document.documentElement.firstElementChild.appendChild(this.bodyStyle);
    //
    // this.setPageTitle()
    // this.bodyScale()
    // window.addEventListener('resize', this.bodyScale, false);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.bodyScale)
    this.bodyStyle.parentNode.removeChild(this.bodyStyle)
  }
}
