import request from '@/utils/request';

//林木良种基地
export function getSumNumGood(query) {
    return request({
        url: '/system/woods_good_base/getSumNum',
        method: 'get',
        params: query,
    });
}
//查询林木良种资源库数量
export function getSumNumSeeds(query) {
    return request({
        url: '/system/woods_seeds_db/getSumNum',
        method: 'get',
        params: query,
    });
}
//查询疫源疫病救助站总数量
export function getSumNumMonitoring(query) {
    return request({
        url: '/system/wild_animal_monitoring_station/getSumNum',
        method: 'get',
        params: query,
    });
}
//分组查询各地区数量-林木良种基地
export function getNumGroupGood(query) {
    return request({
        url: '/system/woods_good_base/getNumGroup',
        method: 'get',
        params: query,
    });
}
//分组查询各地区数量-林木良种资源库数量
export function getNumGroupSeeds(query) {
    return request({
        url: '/system/woods_seeds_db/getNumGroup',
        method: 'get',
        params: query,
    });
}
//分组查询各地区数量-疫源疫病救助站总数量
export function getNumGroupMonitoring(query) {
    return request({
        url: '/system/wild_animal_monitoring_station/getNumGroup',
        method: 'get',
        params: query,
    });
}
//查询国储林总数量
export function nationalStorageDeepForest(query) {
    return request({
        url: '/system/nationalStorageDeepForest/getSumNum',
        method: 'get',
        params: query,
    });
}
//查询欧投行总数量
export function europeanInvestment(query) {
    return request({
        url: '/system/europeanInvestment/getSumNum',
        method: 'get',
        params: query,
    });
}
//林长制人员数量统计接口
export function getLeaderNum(query) {
    return request({
        url: '/system/woodsLeaderNum/getLeaderNum',
        method: 'get',
        params: query,
    });
}
//古木总数
export function getWoodNum(query) {
    return request({
        url: '/system/wood_num/list',
        method: 'get',
        params: query,
    });
}
//林长制人员
export function woodsLeaderUser(query) {
    return request({
        url: '/system/woodsLeaderUser/list',
        method: 'get',
        params: query,
    });
}
//森林防火初始化数据
export function getPageInfo(query) {
    return request({
        url: '/system/pgsql/getPageInfo',
        method: 'get',
        params: query,
    });
}
//根据区划分组查询站点数量
export function getSiteNumGroupByRegion(query) {
    return request({
        url: '/system/pgsql/getSiteNumGroupByRegion',
        method: 'get',
        params: query,
    });
}
//根据区划分组查询火灾数量
export function getFireNumGroupByRegion(query) {
    return request({
        url: '/system/pgsql/getFireNumGroupByRegion',
        method: 'get',
        params: query,
    });
}
// 根据区划分组查询火灾面积
export function getFireAreaGroupByRegion(query) {
    return request({
        url: '/system/pgsql/getFireAreaGroupByRegion',
        method: 'get',
        params: query,
    });
}
//国储林分组
export function getNumGroupNationalStorage(query) {
    return request({
        url: '/system/nationalStorageDeepForest/getNumGroup',
        method: 'get',
        params: query,
    });
}
//获取巡护中的护林员
export function getDuringPatrol(query) {
    return request({
        url: '/system/woodsLeaderNum/getDuringPatrol',
        method: 'get',
        params: query,
    });
}
//自然保护地调整
export function getPageInfoApi(query) {
    return request({
        url: '/system/natureReserve/getPageInfo',
        method: 'get',
        params: query,
    });
}
//自然保护地详情
export function getDetailList(query) {
    return request({
        url: '/system/natureReserve/getDetailList',
        method: 'get',
        params: query,
    });
}
//古木名树
export function listNoPage(query){
    return request({
        url: '/system/woodDetail/listNoPage',
        method: 'get',
        params: query,
    });
}
//欧投行
export function europeanInvestmentGroup(query){
    return request({
        url: '/system/europeanInvestment/getNumGroup',
        method: 'get',
        params: query,
    });
}
//国有林场
export function getSumNumArea(query){
    return request({
        url: '/system/countryTreeFarm/getSumNumArea',
        method: 'get',
        params: query,
    });
}
//国有林场分组
export function getNumGroup(query){
    return request({
        url: '/system/countryTreeFarm/getNumGroup',
        method: 'get',
        params: query,
    });
}
//森林防火坐标
export function getDetailListApi(query) {
    return request({
        url:'/system/pgsql/getDetailList',
        method:'get',
        params:query
    })
}
//天气
export function getWeather() {
    return request({
        url:'/system/woodsLeaderNum/getWeather',
        method:'get',
    })
}
//国有林场坐标
export function countryTreeFarm(query) {
    return request({
        url:'/system/countryTreeFarm/getDetailList',
        method:'get',
        params:query
    })
}
//欧投行坐标
export function europeanInvestmentApi(query) {
    return request({
        url:'/system/europeanInvestment/getDetailList',
        method:'get',
        params:query
    })
}
//国储林坐标
export function nationalStorageDeepForestApi(query) {
    return request({
        url:'/system/nationalStorageDeepForest/getDetailList',
        method:'get',
        params:query
    })
}
//野生动物坐标
export function nationalStorageDeepForestsApi(query) {
    return request({
        url:'/system/nationalStorageDeepForest/getDetailList',
        method:'get',
        params:query
    })
}
//良种资源库坐标
export function woodsSeedsDb(query) {
    return request({
        url:'/system/woods_seeds_db/getDetailList',
        method:'get',
        params:query
    })
}
//林木良种基地坐标
export function woodsGoodBase(query) {
    return request({
        url:'/system/woods_good_base/getDetailList',
        method:'get',
        params:query
    })
}
//森林总面积
export function getSumArea(query) {
    return request({
        url:'/system/deepForestResources/getSumArea',
        method:'get',
        params:query
    })
}
