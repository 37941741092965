<template>
  <div class="header">
    <div class="title">
      {{title}}
    </div>
    <div class="header-right">
      <div class="home-btn" @click="goHomePage"></div>
      <div class="date-wrap">
        <div class="time">
          {{dateCounter.getCurTime && dateCounter.getCurTime()}}
        </div>
        <div class="date-text">
          {{dateCounter.getCurDate && dateCounter.getCurDate()}}
        </div>
      </div>
<!--      <i class="weather-icon sun"></i>-->
      <span style="font-size: 18px;margin:0 10px">{{dataInfo.weather}}</span>
      <div class="temperature-wrap">
        <i class="temperature-icon"></i>
        {{dataInfo.degree}} ℃
      </div>
    </div>
  </div>
</template>

<script>
import { DateCounter } from "@/utils";
import {getWeather} from '@/api/integratedPlatform.js'
export default {
  props: ['title'],
  data(){
    return {
      dateCounter: {},
      dataInfo:{}
    }
  },
  methods: {
    goHomePage(){
      this.$router.push({
        path: '/integratedPlatform'
      })
    },
    getWeatherFun(){
      getWeather().then(res=>{
        if(res.code==200){
          this.dataInfo=res.data
          console.log(this.dataInfo,'0000')
        }
      })
    },
  },
  mounted() {
    this.getWeatherFun()
    this.dateCounter = new DateCounter();
  },
}
</script>
<style lang="scss" scoped>
.header{
  position: relative;
  .title{
    letter-spacing: 10px;
    width: 100%;
    height: 122px;
    line-height: 122px;
    font-family: PangMenZhengDao;
    font-weight: 400;
    font-size: 54px;
    color: #EFF8FC;
    text-shadow: 0px 4px 1px rgba(19,80,143,0.66);
    opacity: 0.89;
  }
  .header-right{
    position: absolute;
    right: 24px;
    top: 54px;
    display: flex;
    flex-wrap: nowrap;
    .home-btn{
      cursor: pointer;
      margin: 0 26px;
      display: block;
      width: 26px;
      height: 26px;
      background: url('./images/home.jpg') center center no-repeat;
      background-size: 100%;
    }
    .date-wrap{
      border-right: 2px solid #9EC5E7;
      padding-right: 24px;
      text-align: right;
      white-space: nowrap;
      .time{
        width: 79px;
        height: 17px;
        font-family: TRENDS;
        font-weight: 400;
        font-size: 18px;
        color: #CDD6E3;
        line-height: 20px;
      }
      .date-text{
        width: 100%;
        height: 8px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        font-size: 9px;
        color: #CDD6E3;
        line-height: 20px;
        margin-left: 7px;
        text-align: right;
        transform: scale(0.8);
      }
    }
    .weather-icon{
      margin: 0 26px;
      display: block;
      width: 26px;
      height: 26px;
      background: url('./images/sun.png') center center no-repeat;
      background-size: 100%;
    }
    .temperature-wrap{
      .temperature-icon{
        width: 12px;
        height: 22px;
        display: inline-block;
        background: url('./images/thermometer.png') center center no-repeat;
        background-size: 100%;
      }
      font-family: TRENDS;
      font-weight: 400;
      font-size: 23px;
      color: #CDD6E3;
      line-height: 20px;
    }
  }
}
</style>
